"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
exports.NUtils = void 0;
var wasm_1 = require("./classes/wasm");
var NUtils;
(function (NUtils) {
    function getRandom(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }
    NUtils.getRandom = getRandom;
    function arrayItemDelete(arr, index) {
        var j = index;
        while (j < arr.length) {
            arr[j] = arr[j + 1];
            j++;
        }
        arr.pop();
    }
    NUtils.arrayItemDelete = arrayItemDelete;
    function arrayItemToLast(arr, index) {
        var str = arr.splice(index, 1);
        arr.push(str[0]);
    }
    NUtils.arrayItemToLast = arrayItemToLast;
    function isObjectEqual(obj1, obj2) {
        var isEqual = true;
        for (var i in obj1) {
            if (typeof obj1[i] == "object" && typeof obj2[i] == "object") {
                if (!isObjectEqual(obj1[i], obj2[i]))
                    isEqual = false;
                continue;
            }
            if (obj1[i] != obj2[i])
                isEqual = false;
        }
        return isEqual;
    }
    NUtils.isObjectEqual = isObjectEqual;
    function sleep(time) {
        return new Promise(function (resolve) { return setTimeout(resolve, time); });
    }
    NUtils.sleep = sleep;
    var WASM = /** @class */ (function (_super) {
        __extends(WASM, _super);
        function WASM() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return WASM;
    }(wasm_1["default"]));
    NUtils.WASM = WASM;
})(NUtils = exports.NUtils || (exports.NUtils = {}));
